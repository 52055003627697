import logo from './logo750.png';

function App() {
  return (
    <div className='App'>
      <div className='logo' style={{ backgroundImage: 'url(' + logo + ')' }}></div>
    </div>
  );
}

export default App;
